import { Injectable, Provider } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

@Injectable()
class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {direction: Hammer.DIRECTION_ALL},
  };
}


export function ProvideHammer(): Provider {
  return {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerConfig,
  };
}
