import { Environment } from '@rxap/environment';

export const environment: Environment = {
  name: 'production',
  production: true,
  app: 'mobile-dashboard',
  serviceWorker: true,
  sentry: {
    dsn: 'https://1581a6ff913340f5b6494eff192ec6a3@sentry.eurogard.cloud/4',
    enabled: true,
    debug: false,
  },
};
