import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { ProvideEnvironment } from '@rxap/environment';
import { HttpErrorInterceptor, ProvideErrorHandler } from '@rxap/ngx-error';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { HammerModule } from '@angular/platform-browser';
import { provideServiceWorker } from '@angular/service-worker';
import { ProvideServiceWorkerUpdater, withAutoUpdater, withLogUpdater } from '@rxap/service-worker';
import { KeycloakBearerInterceptor } from '@rxap/keycloak';
import { AuthHttpInterceptor, CorsInterceptor, ProvideAuth } from 'eurogard-authentication';
import { ProvideI18nMatIntl } from 'angular-components/localize/provide';
import { ProvideIconAssetPath } from 'angular-application-providers/provide-icon-asset-path';
import { ProvideErrorInterceptorOptions } from 'angular-application-providers/provide-error-interceptor-options';
import { MarkdownModule } from 'ngx-markdown';
import { LanguageInterceptor } from '@rxap/ngx-localize';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { ProvideHammer } from 'angular-application-providers/provide-hammer';
import { ProvidePubSub } from '@rxap/ngx-pub-sub';
import { ProvideDate } from 'angular-application-providers/provide-date';
import { provideTheme } from 'angular-services/theme/provide';
import { provideSocketIoFromConfig } from 'angular-services/socket-io/provide';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      HammerModule,
      provideFirebaseApp(() => initializeApp({
        apiKey: 'AIzaSyCaaBujshxKsZ_vGNcds6X_FjxuSeg-5MY',
        authDomain: 'eurogard-cloud-app.firebaseapp.com',
        databaseURL: 'https://eurogard-cloud-app.firebaseio.com',
        projectId: 'eurogard-cloud-app',
        storageBucket: 'eurogard-cloud-app.appspot.com',
        messagingSenderId: '938611462624',
        appId: '1:938611462624:web:e026037d800080d3531b85',
        measurementId: 'G-8Y7LQ17TSX',
      })),
      provideMessaging(() => getMessaging()),
      MarkdownModule.forRoot(),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // required to be imported so that the http client is available in the application for the status check method
    provideHttpClient(withInterceptors([
      HttpErrorInterceptor,
      AuthHttpInterceptor,
      CorsInterceptor,
      KeycloakBearerInterceptor,
      LanguageInterceptor,
    ])),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    ProvideErrorHandler(),
    ProvideAuth(true),
    ProvideI18nMatIntl(),
    ProvideIconAssetPath(),
    ProvideErrorInterceptorOptions(),
    ProvideEnvironment(environment),
    ProvideServiceWorkerUpdater(withLogUpdater(), withAutoUpdater()),
    provideSocketIoFromConfig(),
    ProvideHammer(),
    ProvidePubSub(),
    ProvideDate(),
    ProvideEnvironment(environment),
    provideTheme(),
  ],
};
